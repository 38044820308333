<template>
	<div>
		<el-dialog title="选择参照标的物" :close-on-click-modal="false" :visible.sync="visible"
			width="1400px">
			<el-card body-style="padding-bottom: 0;">
				<el-form ref="form" :model="form" inline label-width="120px" label-position="left">
					<el-row>
						<el-col :span="20">
							<el-form-item label="标的物名称">
								<el-input class="selItemInput descAreaLg" v-model="subjectName" type="input" rows="1" placeholder="请填写"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="4"  class="selBtnGroup">
							<el-button v-preventReClick class="primaryPlainBtn" type="primary" plain @click="resetFormData()">重置</el-button>
							<el-button v-preventReClick class="primaryPlainBtn" type="primary" @click="selGetDataList()">检索</el-button>
						</el-col>
					</el-row>
				</el-form>
			</el-card>
			<el-row style="margin-top: 20px;">
				<el-col :offset="12" :span="12" style="text-align: right;">
					<el-pagination
							background
							@current-change="currentChangeHandle"
							layout="prev, pager, next"
							:current-page="pageIndex"
							:page-size="pageSize"
							:total="totalPage">
					</el-pagination>
				</el-col>
			</el-row>
			<el-table
			  class="dataListTable"
			  :data="dataList"
			  header-align="center"
			  height="360">
				<el-table-column type="expand">
					<template slot-scope="props">
						<el-table
								:data="props.row.paramInfoList"
								style="width: 100%" :show-header="false">
							<el-table-column label="参数名称" prop="paramName" width="300" align="center">
							</el-table-column>
							<el-table-column label="参数值" align="center" prop="paramValue">
							</el-table-column>
						</el-table>
					</template>
				</el-table-column>
			  <el-table-column type="index" width="60" align="center">
			  </el-table-column>
			  <el-table-column prop="projectNo" label="工程编号" width="200" align="center">
			  </el-table-column>
			  <el-table-column prop="subjectName" label="标的物名称"align="left" header-align="center">
			  </el-table-column>
			  <el-table-column
					  label="操作" 
					  align="center">
				  <template slot-scope="scope">
					  <el-button v-preventReClick type="danger" size="small" @click="selectSubject(scope.row.subjectNo)">选择</el-button>
				  </template>
			  </el-table-column>
			</el-table>
			<el-row class="formBtnGroup">
				<el-button v-preventReClick class="primaryPlainBtn" type="primary" plain @click="visible = false">返回</el-button>
			</el-row>
		</el-dialog>
	</div>
</template>

<script>
	import $common from "@/utils/common.js"
	export default {
		name: "subject-select",
		data() {
			return {
				visible: false,
				inline: false,
				subjectName: '',
				workNo: $common.getItem("workNo"),
				contractId:"",
				dataList:[],
				//当前页码
				pageIndex: 1,
				//当前记录数
				pageSize: 4,
				//总页数
				totalPage: 0,
			}
		},
		components: {
		},
		methods: {
			init() {
				this.pageIndex = 1;
				this.getDataList();
				this.$nextTick(() => {
					this.visible = true;
				})
			},
			siteContentViewHeight () {
				var height = this.$store.state.common.documentClientHeight - 660;
				return { height: height + 'px' }
			},
			currentChangeHandle(val) {
				this.pageIndex = val;
				this.getDataList()
			},
			resetFormData(){
				this.subjectName = "";
			},
			selGetDataList(){
				this.pageIndex = 1;
				this.getDataList();
			},
			selectSubject(subjectNo){
				let _url = "/business/subjectinfo/saveBySelectSubject/"+this.workNo+"/"+subjectNo;
				this.$http({
					url: this.$store.state.httpUrl + _url,
					method: "post"
				}).then(({ data }) => {
					if (data && data.resultCode === 200) {
						this.$message({
							message: "操作成功",
							type: "success",
							duration: 1500,
							onClose: () => {
								this.visible = false;
								this.$parent.getDataList();
							}
						});
					} else {
						this.$message.error(data.msg);
					}
				});
			},
			getDataList(){
				this.$http({
				  url:  this.$store.state.httpUrl + "/business/subjectinfo/selectList",
				  method: "get",
				  params: {
					  subjectName: this.subjectName,
					  workNo: this.workNo,
					  limit: this.pageSize,
					  page: this.pageIndex
				  },
				  }).then(({ data }) => {
					if (data && data.resultCode === 200) {
						this.dataList = data.body.records;
						this.totalPage = data.body.totalCount;
					}
				});
			},
		}
	}
</script>
<style lang="scss"  scoped>
	.selItemInput.descAreaLg{
		width: 718px !important;
	}
</style>
